import { Grid } from "@mui/material";
import { useCallback } from "react";
import {
  BulkDeleteButton,
  Create,
  Datagrid,
  DeleteButton,
  Labeled,
  List,
  NumberInput,
  Pagination,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
  ImageField,
  ImageInput,
  DateInput

} from "react-admin";
import { format } from 'date-fns';
import React from 'react';






const AppPuzzleFilters = [
  <TextInput label="Puzzle Name" source="name__icontains" alwaysOn />,

];

const AppPuzzleBulkActionButtons = () => (
  <>
    <BulkDeleteButton label="Archive & Deactivate" />
  </>
);

const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;

const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton label="Update" />
      <DeleteButton label="Archive & Deactivate" sx={{ textAlign: "right" }} />
    </Toolbar>
  );
};




export const AppPuzzleList = () => (
  <List filters={AppPuzzleFilters} pagination={<MyPagination />}>
    <Datagrid bulkActionButtons={<AppPuzzleBulkActionButtons />}>
      {/* rowClick="edit" */}
      <TextField source="name" />
      <TextField source="seasonStart" />
      <TextField source="seasonEnd" />
      <TextField source="nPieces" />
      <TextField source="accessLevel" />
      <Labeled  label="Puzzle Pic" fullWidth={true}>
      <ImageField sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }} source="imageLink" />
      </Labeled>
    </Datagrid>
  </List>
);


  const AppPuzzleCreateEditForm: React.FC = () => {
    

    const formattedDate = (date: Date): string => {
      return format(date, 'yyyy-MM-dd');
    };

    // Usage
    const date = formattedDate(new Date());

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Name" fullWidth={true}>
          <TextInput source="name" fullWidth={true} />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Puzzle Start Date " fullWidth={true}>
          <DateInput fullWidth={true} label="Puzzle Start Date" source="seasonStart" defaultValue={date} helperText="Select the date puzzle will start in format DD-MM-YYYY." />
        </Labeled>
        <Labeled label="Puzzle End Date" fullWidth={true}>

          <DateInput fullWidth={true} label="Puzzle End Date" source="seasonEnd" defaultValue={date} helperText="Select the date puzzle will finish in format DD-MM-YYYY." />
        </Labeled>

      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5} sx={{ textAlign: "right" }}>
        <Labeled label="Access Level" fullWidth={true}>
          <TextInput fullWidth label="Access Level" source="accessLevel" defaultValue="basic" />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5}>
         <Labeled label="Puzzle Pic" fullWidth={true}>
         <ImageInput source="imageLink" label="Related pictures" multiple={false}>
             <ImageField source="src" title="title" />
         </ImageInput>
        </Labeled>
      </Grid>
    </Grid>
  );
};


export const AppPuzzleCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("appPuzzle", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  return (
    <Create>
      <SimpleForm>
        <AppPuzzleCreateEditForm></AppPuzzleCreateEditForm>
      </SimpleForm>
    </Create>
  );
};
