import { Alert, Badge } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ArrayInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceInput,
  SaveButton,
  SearchInput,
  SelectColumnsButton,
  SelectInput,
  SimpleFormIterator,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  TranslatableInputs,
  WrapperField,
  required,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { CircularProgressWithLabel } from "../components/CircularProgressWithLabel";
import { LocaleReferenceArrayInput } from "../components/LocaleReferenceArrayInput";
import { SummaryTabHeader } from "../components/SummaryTabHeader";
import { TranslationsTabHeader } from "../components/TranslationsTabHeader";
import dataProvider from "../providers/grapheneDataProvider2";
import { Locale } from "./locales";

const userFilters = [<SearchInput source="name__icontains" alwaysOn={true} placeholder="Name" />];

export const EmailTemplateTranslationsForm = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info">"en-GB" fields are required</Alert>
      <TranslatableInputs
        locales={translationLocaleKeys}
        defaultLocale={"en-GB"}
        sx={{ minWidth: "600px" }}
        groupKey="translations"
        fullWidth
      >
        <ReferenceInput source="translations.marketoCampaignId" reference="marketoCampaigns">
          <SelectInput
            label="Marketo Campaign"
            optionText={(record) => (
              <div style={{ display: "unset " }}>
                <span>
                  <b>{record.name}</b> - #{record.id}
                </span>
                <br />
                <span>Workspace: {record.workspace}</span>
              </div>
            )}
            helperText="Marketo Smart Campaign that triggers the template for this email type"
          />
        </ReferenceInput>

        <TextInput source="translations.mailchimpTemplateName" label="Mailchimp Template Name" helperText="" />
        <TextInput source="translations.subjectText" label="Subject Text" helperText="" />
        <TextInput source="translations.previewText" label="Preview Text" helperText="" />
        <TextInput
          source="translations.fromEmail"
          label="From Email"
          helperText=""
          placeholder="no-reply@playbrush.com"
          focused
        />
        <TextInput
          source="translations.fromName"
          label="From Name"
          helperText=""
          placeholder="Playbrush GmbH"
          focused
        />
        <ArrayInput source="translations.attachments" label="Attachments">
          <SimpleFormIterator inline fullWidth>
            <TextInput source="fileName" sx={{ width: "20%" }} validate={required()} />
            <TextInput source="fileType" sx={{ width: "20%" }} validate={required()} />
            <TextInput
              source="fileLocation"
              sx={{ width: "55%" }}
              helperText="URL or relative path"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </TranslatableInputs>
    </>
  );
};

export const EmailTemplatesEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <TabbedForm syncWithLocation={false} toolbar={<TemplateEditToolbar />}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="emailType" disabled fullWidth />
          <TextInput source="description" fullWidth />
          <LocaleReferenceArrayInput />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <EmailTemplateTranslationsForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

const SaveButtonWithMessage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = (data: any) => {
    notify(`Template saved. ${data.updateMessage || ""}`, { type: data.updateMessage ? "warning" : "success" });
    redirect("list", "emailTemplates");
  };
  return <SaveButton type="button" mutationOptions={{ onSuccess }} />;
};

const TemplateEditToolbar = () => (
  <Toolbar>
    <SaveButtonWithMessage />
  </Toolbar>
);

export const EmailTemplatesCreate = () => {
  return (
    <Create>
      <TabbedForm syncWithLocation={false} toolbar={<TemplateEditToolbar />}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="emailType" disabled fullWidth />
          <TextInput source="description" fullWidth />
          <LocaleReferenceArrayInput />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <EmailTemplateTranslationsForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export const MyActions = ({ showCreate = false }) => {
  return (
    <TopToolbar>
      {showCreate ? <CreateButton /> : ""}
      <FilterButton />
      <SelectColumnsButton />
    </TopToolbar>
  );
};

export const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const filterToQueryTags = (searchText: string) => ({
  name__icontains: `${searchText}`,
});
export const bundleFilters = [<TextInput label="Email Type" source="email_type__icontains" alwaysOn />];

export const EmailTemplatesList = (props: any) => {
  return (
    <List filters={bundleFilters} actions={<MyActions {...props} />} pagination={<MyPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="emailType" />
        <ReferenceArrayField label="Locales" reference="locales" source="locales">
          <SingleFieldList>
            <Badge color="secondary" badgeContent={<TextField source="supportedLanguages.length" />}>
              <ChipField source="name" />
            </Badge>
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Translation Completion"
          render={(record: any) => {
            return (
              <CircularProgressWithLabel
                value={
                  record.translationsCount && record.languageLocales.length
                    ? (record.translationsCount / record.languageLocales.length) * 100
                    : 0
                }
              />
            );
          }}
        />
        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
